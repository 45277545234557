@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

.projectbg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;
}

.projectfr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

section#main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
}

#main .container_proj {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

#main .container_proj .card {
    position: relative;
    width: 280px;
    height: 400px;
    margin: 20px 12px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#main .container_proj .card .imgBx img {
    object-fit: cover;
    width: 100%;
}

.text-white{
    color: #fff;
}

.text-black{
    color: #000;
}

#main .container_proj .card .content {
    position: absolute;
    bottom: -210px;
    width: 100%;
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    flex-direction: column;
    backdrop-filter: blur(15px);
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    transition: bottom 0.5s;
    transition-delay: 0.65s;
}

#main .container_proj .card:hover .content {
    bottom: 0;
    transition-delay: 0s;
}

#main .container_proj .card .content .contentBx h3 {
    letter-spacing: 0.05rem;
    font-size: 25px;
    font-family: 'Source Serif Pro', serif;
    text-align: center;
    margin-top: 10px;
    line-height: 1em;
    transition: 0.5s;
    transition-delay: 0.6s;
    opacity: 0;
    transform: translateY(-20px);
    font-weight: 600;
}

#main .container_proj .card:hover .content .contentBx h3 {
    opacity: 1;
    transform: translateY(0);
    font-weight: bolder;
}

#main .container_proj .card .content .contentBx h3 span {
    font-size: 17px;
    letter-spacing: 0.05rem;
    font-weight: 500;
    text-transform: initial;
}

#main .container_proj .card .content .sci {
    position: relative;
    bottom: 5px;
    display: flex;
    margin-left: -30px;
}

#main .container_proj .card .content .sci li {
    list-style: none;
    margin: 0 10px;
    transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
    transition-delay: 0.2s;
}

#main .container_proj .card .content .sci .web{
    transition-delay: 0.4s;
}

#main .container_proj .card:hover .content .sci li {
    transform: translateY(0);
    opacity: 1;
}

#main .container_proj .card .content .sci li a {
    color: white;
    font-size: 24px;
}

.btn_center {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    padding-bottom: 100px;
}

@media (max-width: 750px) {
    .projectfr {
        position: absolute;
        width: 80vw;
        height: 29px;
        font-size: 50px;
    }
    .projectbg {
        display: none;
    }
}