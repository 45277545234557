@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Baskerville:ital@1&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap");

.contact_section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  font-family: "Libre Baskerville", serif;
}

.contact_description {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.contact_body {
  padding-top: 20px;
  font-family: "Libre Baskerville", serif;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 1px;
}

.contact_right {
  align-items: left;
}

.formEmail{
  letter-spacing: 0.08rem;
}
.fromEmail__mail{
  color: #45c7e7;
}

.form_control_input {
  width: 100%;
  border-radius: 15px;
  max-height: 45px;
  min-height: 45px;
  font-size: 23px;
  font-family: "Libre Baskerville", serif;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-top: 5px;
}

textarea:focus {
  outline: none;
  border: 1px solid #2196f3;
}

.form_control_textarea {
  width: 100%;
  border-radius: 15px;
  font-size: 23px;
  margin-bottom: 15px;
  font-family: "Libre Baskerville", serif;
  min-height: 300px;
  padding-left: 5px;
  padding-top: 5px;
}

.hover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 210px;
  height: 50px;
  background-color: #4db6ac;
  border-radius: 99px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  margin-top: 30px;
  letter-spacing: 1px;
}

.hover:before,
.hover:after {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.hover:before {
  content: "";
  left: 0;
  justify-content: flex-end;
  background-color: #4db6ac;
}

.hover:after {
  content: "";
  right: 0;
  justify-content: flex-start;
  background-color: #44a69c;
}

.hover:hover {
  background-color: #46627f;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.hover:hover span {
  opacity: 0;
  z-index: -3;
}

.hover:hover:before {
  opacity: 0.5;
  transform: translateY(-100%);
}

.hover:hover:after {
  opacity: 0.5;
  transform: translateY(100%);
}

.hover span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-family: "Libre Baskerville", serif;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}

.hover .social-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
}

.hover .social-link i {
  text-shadow: 1px 1px rgba(70, 98, 127, 0.7);
  transform: scale(1);
}

.hover .social-link:hover {
  background-color: rgba(245, 245, 245, 0.1);
}

.hover .social-link:hover i {
  animation: bounce 0.4s linear;
}

@keyframes bounce {
  40% {
    transform: scale(1.4);
  }
  60% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.img-dev {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

@media (max-width: 750px) {
  .contact_description {
    margin-top: 30px;
  }
  .contact_left,
  .formLogo {
    font-size: 2rem;
  }
  .contact_body {
    font-size: 1.2rem;
  }
  .contact_section {
    margin-top: 80px;
  }
  textarea {
    width: 100%;
  }
}
