@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

.vol_exp-Bg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;

}

.vol_exp-Fr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

@media (max-width: 750px) {
    .vol_exp-Fr {
        position: absolute;
        width: 80vw;
        font-size: 8vw;
    }
    .vol_exp-Bg {
        display: none;
    }
}

.cards-list {
    z-index: 0;
    padding-top: 150px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-bottom: 50px;
}

.card {
    margin: 30px 4px;
    width: 300px;
    height: 345px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    transition: 0.4s;
}

.card .card-image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
}

.card .card-image img {
    width: 100%;
    height: auto;
    border-radius: 40px;
    object-fit: cover;
    margin-left: -1px;
    margin-top: -1px;
}

.card .card-title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-size: 30px;
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
}

.card:hover {
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
    color: white;
}

.title-black {
    color: black;
    font-weight: bolder;
}

@media all and (max-width: 500px) {
    .card-list {
        padding-top: -200px;
        flex-direction: column;
    }
}