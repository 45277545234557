@import url('https://fonts.googleapis.com/css2?family=Vollkorn:ital@1&display=swap');

.footer_text {
    text-align: center;
    font-family: 'Vollkorn', serif;
    font-size: 25px;
    letter-spacing: 1px;
    padding-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;
}

.footer_ash {
  text-align: center;
  font-family: 'Vollkorn', serif;
  font-size: 20px;
  letter-spacing: 1px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}


.footer_icon {
    padding-bottom: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  #React-logo-footer {
    margin-bottom: 5px;
    animation: React-logo-spin infinite 10s linear;
  }
}
