@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Baskerville:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Baskerville:ital@1&family=Source+Serif+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css?family=Ubuntu);
@import url(https://fonts.googleapis.com/css?family=Ubuntu+Mono);
@import url(https://fonts.googleapis.com/css2?family=Gentium+Basic:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Libre+Baskerville:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vollkorn:ital@1&display=swap);
html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: 500;
}

/* Media Query */
/*# sourceMappingURL=index.css.map */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #171c28;
}

::-webkit-scrollbar-thumb {
  background-color: #55198b !important;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(126, 38, 185, 0.959);
  border-radius: 12px;
}

.preloader{
  display: block;
  margin: 30vh auto;
}
.dark-mode {
  background-color: #111827;
  color: rgb(247, 247, 247);
  transition: .1s;
}
.dark-menu {
    background-color: #111827 !important;
    color: white;
}

.dark-menu li a {
    color: white !important;
}

.dark-menu li a:hover {
    background-color: #55198b !important;
}

header {
    z-index: 15;
}

.header {
    background-color: #fff;
    max-width: 100%;
    padding: 10px 10px;
    margin: 0px auto;
    top: 0px;
}

.header ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    cursor: pointer;
    font-family: "Source Serif Pro", serif;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.grey-color {
    color: #868e96;
}

.header li a {
    display: block;
    padding: 8px 10px;
    font-size: 1.2rem;
    color: black;
    text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
    background-color: #f4f4f4;
    border-radius: 15px;
}

.header .logo {
    display: block;
    -webkit-flex: none;
            flex: none;
    float: left;
    font-size: 2em;
    text-decoration: none;
    margin-top: -8px;
    line-height: normal;
    padding-left: 20px;
}

.header .logo-name {
    font-family: "Dancing Script", cursive;
    font-weight: bold;
    font-feature-settings: "liga", "clig off";
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-variant-ligatures: no-common-ligatures;
    padding: 0 10px;
    text-decoration: none;
    color: #007bff;
}

/* menu */

.header .menu {
    clear: both;
    max-height: 0;
    transition: max-height 0.2s ease-out;
}

/* menu icon */

.header .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.header .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon-dark:before,
.header .menu-icon .navicon-dark:after {
    background: white !important;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
}

.header .menu-icon .navicon:before {
    top: 5px;
}

.header .menu-icon .navicon:after {
    top: -5px;
}

/* menu btn */

.header .menu-btn {
    display: none;
}

.header .menu-btn:checked ~ .menu {
    max-height: 486px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
    .header li {
        float: left;
    }
    .header {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .header .menu {
        max-height: none;
    }
    .header .menu-icon {
        display: none;
    }
}

@media (max-width: 960px) and (min-width: 768px) {
    .header {
        font-size: 12px;
    }
}

@media (max-width: 750px) {
    .header .logo {
        margin-top: 13px;
        font-size: 1.5rem;
        padding-left: 10px;
    }
}

@media (min-width: 750px) and (max-width: 1025px) {
    .header .logo {
        font-size: 1.5rem;
    }
    .header li a {
        font-size: 0.8rem;
    }
}
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

input {
  -webkit-appearance: none;
  position: relative;
  width: 56px;
  height: 28px;
  background-image: url(https://i.postimg.cc/857jHw2q/Screenshot-2020-04-16-at-1-07-06-PM.png);
  background-size: cover;
  border-radius: 50px;
  outline: none;
  transition: background-image .90s;
  box-shadow: 0px 2px 5px 1px gray;
}

input:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: navy;
  border-radius: 50px;
  transition: all .9s;
  background-color: #F7CA33;
}

input:checked {
  background-image: url(https://i.postimg.cc/Hn0nstVK/Screenshot-2020-04-16-at-1-07-19-PM.png);
  transition: background-image .90s;
}

input:checked:before {
  -webkit-transform: translate(100%);
          transform: translate(100%);
  transition: all .9s;
  background-color: #ECF0F3;
}
.about__section {
  position: relative;
  padding-bottom: 40px !important;
  padding-top: 70px;
}

.about__description {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
}

.text__center {
  text-align: center;
  margin-bottom: -25px;
}

.about__intro {
  font-family: "Libre Baskerville", serif;
  letter-spacing: 1px;
  font-size: 2.6rem;
}

.text_center_link {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 12px;
  gap: 12px;
  margin-right: 12px;
}

.text_center_link img {
  height: 50px;
  width: 50px;
}

.about__intro img {
  padding-bottom: 20px;
}

.about__body {
  padding-top: 50px;
  font-size: 18px !important;
  text-align: left;
}

#container {
  display: -webkit-flex;
  display: flex;
}

#terminal {
  width: 80vw;
}

#terminal__bar {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  height: 30px;
  padding: 0 8px;
  box-sizing: border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: linear-gradient(#504b45 0%, #3c3b37 100%);
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.5);
}

#bar__buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.bar__button {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0;
  margin-right: 5px;
  font-size: 8px;
  height: 12px;
  width: 12px;
  box-sizing: border-box;
  border: none;
  border-radius: 100%;
  background: linear-gradient(#7d7871 0%, #595953 100%);
  text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 1px 0px #41403A, 0px 1px 1px 0px #474642;
}

.bar__button:hover {
  cursor: pointer;
}

.bar__button:focus {
  outline: none;
}

#bar__button--exit {
  background: linear-gradient(#f37458 0%, #de4c12 100%);
  background-clip: padding-box;
}

#bar__user {
  color: #d5d0ce;
  margin-left: 6px;
  font-size: 14px;
  line-height: 15px;
  margin-top: 8px;
}

#terminal__body {
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.5);
  background: rgba(56, 4, 40, 0.9);
  font-family: 'Ubuntu Mono';
  padding-top: 2px;
  margin-top: -1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#terminal__bio {
  color: #dddddd;
  padding: 4px 10px;
  font-family: 'Ubuntu Mono';
  font-size: 18px;
  text-align: left;
}

#terminal__prompt {
  padding-left: 10px;
  display: -webkit-flex;
  display: flex;
}

#terminal__prompt--user {
  color: #7eda28;
}

#terminal__prompt--location {
  color: #4878c0;
}

#terminal__prompt--bling {
  color: #dddddd;
}

#terminal__prompt--cursor {
  display: block;
  height: 17px;
  width: 8px;
  margin-left: 9px;
  -webkit-animation: blink 1200ms linear infinite;
          animation: blink 1200ms linear infinite;
  margin-top: 4px;
  margin-bottom: 65px;
}

@-webkit-keyframes blink {
  0% {
    background: #ffffff;
  }
  49% {
    background: #ffffff;
  }
  60% {
    background: transparent;
  }
  99% {
    background: transparent;
  }
  100% {
    background: #ffffff;
  }
}

@keyframes blink {
  0% {
    background: #ffffff;
  }
  49% {
    background: #ffffff;
  }
  60% {
    background: transparent;
  }
  99% {
    background: transparent;
  }
  100% {
    background: #ffffff;
  }
}

.text__center a {
  text-decoration: none;
}

.profile_image {
  border-radius: 50%;
  max-width: 350px;
}

.myAvtar {
  margin-top: 50px;
  -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, .44));
          filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, .44));
}

.btn{
  width: 200px;
  height: 40px;
  font-size: 20px;
  font-family: "Source Serif Pro", serif;
  font-weight: bold;
  text-align: center;
  line-height: 25px;
  letter-spacing: 1.2px;
  color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  background: linear-gradient(-45deg, #ffa63d, #ff3d77, #338aff, #3cf0c5);
  background-size: 600%;
  -webkit-animation: anime 16s linear infinite;
  animation: anime 16s linear infinite;
}

@-webkit-keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes anime {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 750px) {
  .about__section {
    margin-top: -30px;
  }
  .text_center_link {
    grid-gap: 5px;
    gap: 5px;
  }
  .text_center_link img {
    height: 45px;
    width: 45px;
  }
  #terminal {
    width: 100%;
  }
  #terminal__prompt--cursor {
    margin-top: 0.3rem;
  }
  .myAvtar {
    display: grid;
    place-items: center;
  }
  .profile_image {
    width: 75vw;
  }
}
.skillsbg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;
}

.skillsfr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

@media (max-width: 750px) {
    .skillsfr {
        position: absolute;
        width: 80vw;
        height: 29px;
        font-size: 50px;
    }
    .skillsbg {
        display: none;
    }
    .skill_images ul li {
        display: none;
    }
    .skill_images {
        display: grid;
        place-items: center;
    }
    .skill_images .My_Skill {
        width: 75px;
    }
    .MySkillName:before {
        margin-top: -15px;
        margin-left: -10px;
    }
}

.skills_section {
    position: relative;
    padding-top: 180px !important;
}

.skills_intro {
    font-family: 'Gentium Basic', serif;
    font-size: 2.5rem;
}

.skills_description {
    padding-top: 10px !important;
    padding-bottom: 20px !important;
    text-align: center;
    width: 100vw;
}

.skills_body {
    padding-top: 50px;
    font-size: 1.2em !important;
    text-align: left;
}

.img_skill {
    width: 110%;
    padding: 10px;
    margin-left: -5%;
    -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.skill_images {
    display: block;
    -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
            filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

.My_Skill {
    width: 90px;
    cursor: pointer;
}

.skill_images ul {
    list-style: none;
}

.skill_images ul li {
    display: inline;
    list-style: none;
    float: left;
    padding: 0 10px 35px 0;
    text-align: center;
    font-family: 'Gentium Basic', serif;
    letter-spacing: 0.1rem;
    font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
    #React-logo {
        -webkit-animation: React-logo-spin infinite 10s linear;
                animation: React-logo-spin infinite 10s linear;
    }
}

@-webkit-keyframes React-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes React-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

[data-tooltip][aria-label] {
    position: relative;
}

[data-tooltip][aria-label]::before {
    content: attr(aria-label);
    pointer-events: none;
    white-space: pre;
    position: absolute;
    color: #fff;
    background: #555;
    padding: 0.08rem 0.5rem;
    border-radius: 0.3rem;
    opacity: 0;
    visibility: none;
}

[data-tooltip][aria-label]::after {
    content: "";
    pointer-events: none;
    width: 0;
    height: 0;
    position: absolute;
    border-color: #555;
    border-width: 0;
    border-style: solid;
    opacity: 0;
    visibility: none;
}

[data-tooltip^="down"][aria-label]::before {
    top: calc(2.15rem + 100%);
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
}

[data-tooltip^="down"][aria-label]::after {
    border-bottom-width: 0.5rem;
    border-right-width: 0.5rem;
    border-right-color: #0000;
    border-left-width: 0.5rem;
    border-left-color: #0000;
    top: 250%;
    right: 50%;
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
}

[data-tooltip][aria-label]:hover::before,
[data-tooltip][aria-label]:hover::after {
    visibility: visible;
    opacity: 1;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
}

[data-tooltip$="100"][aria-label]:hover::before,
[data-tooltip$="100"][aria-label]:hover::after {
    transition-delay: 0.1s;
}

[data-tooltip$="500"][aria-label]:hover::before,
[data-tooltip$="500"][aria-label]:hover::after {
    transition-delay: 0.5s;
}

[data-tooltip$="1000"][aria-label]:hover::before,
[data-tooltip$="1000"][aria-label]:hover::after {
    transition-delay: 1s;
}
.vol_exp-Bg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;

}

.vol_exp-Fr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

@media (max-width: 750px) {
    .vol_exp-Fr {
        position: absolute;
        width: 80vw;
        font-size: 8vw;
    }
    .vol_exp-Bg {
        display: none;
    }
}

.cards-list {
    z-index: 0;
    padding-top: 150px;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-bottom: 50px;
}

.card {
    margin: 30px 4px;
    width: 300px;
    height: 345px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    transition: 0.4s;
}

.card .card-image {
    width: inherit;
    height: inherit;
    border-radius: 40px;
}

.card .card-image img {
    width: 100%;
    height: auto;
    border-radius: 40px;
    object-fit: cover;
    margin-left: -1px;
    margin-top: -1px;
}

.card .card-title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    font-size: 30px;
    font-family: 'Source Serif Pro', serif;
    font-weight: 600;
}

.card:hover {
    box-shadow: 5px 5px 30px 15px rgba(0, 0, 0, 0.25), -5px -5px 30px 15px rgba(0, 0, 0, 0.22);
}

.title-white {
    color: white;
}

.title-black {
    color: black;
    font-weight: bolder;
}

@media all and (max-width: 500px) {
    .card-list {
        padding-top: -200px;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.exp-Bg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;
}

.exp-Fr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

.work-exp {
    padding-top: 100px;
}

.work-exp-list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 4rem 6vw;
    padding: 0;
    list-style: none;
    border-radius: 50px;
    -webkit-justify-content: space-around;
            justify-content: space-around;
}

.exp {
    margin: 30px auto;
    width: 300px;
    height: 420px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;
}

.exp__image {
    background-color: #fff;
    width: 100%;
    height: auto;
    border-radius: 40px;
    object-fit: cover;
}

.exp__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(40) * 1px);
    background-color: rgb(221, 211, 211);
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    transition: 0.2s ease-in-out;
}

.exp:hover .exp__overlay {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.exp__header {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    border-radius: calc(var(40) * 1px) 0 0 0;
    background-color: rgb(221, 211, 211);
    padding: 2em;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    transition: 0.2s ease-in-out;
}

.exp__arc {
    width: 5em;
    height: 5em;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.exp__arc path {
    fill: rgb(221, 211, 211);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.exp:hover .exp__header {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.exp__thumb {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    width: 3em;
    height: 3em;
}

.exp__title {
    font-size: 1.4em;
    margin: 0 0 0.3em;
    color: #312a2e;
    font-family: 'Source Serif Pro', serif;
    font-weight: bold;
}

.exp__status {
    font-size: 1em;
    color: #1a1517;
    font-family: 'Source Serif Pro', serif;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.exp__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #130c10;
    -webkit-box-orient: vertical;
    text-align: justify;
    overflow: hidden;
    font-family: 'Source Serif Pro', serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05rem;
}

@media (max-width: 750px) {
    .exp-Fr {
        position: absolute;
        width: 80vw;
        font-size: 30px;
    }
    .exp-Bg {
        display: none;
    }
    .work-exp-list {
        margin: 4rem 2vw;
    }
}
.projectbg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;
}

.projectfr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

section#main {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-top: 150px;
}

#main .container_proj {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
}

#main .container_proj .card {
    position: relative;
    width: 280px;
    height: 400px;
    margin: 20px 12px;
    overflow: hidden;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

#main .container_proj .card .imgBx img {
    object-fit: cover;
    width: 100%;
}

.text-white{
    color: #fff;
}

.text-black{
    color: #000;
}

#main .container_proj .card .content {
    position: absolute;
    bottom: -210px;
    width: 100%;
    height: 210px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    transition: bottom 0.5s;
    transition-delay: 0.65s;
}

#main .container_proj .card:hover .content {
    bottom: 0;
    transition-delay: 0s;
}

#main .container_proj .card .content .contentBx h3 {
    letter-spacing: 0.05rem;
    font-size: 25px;
    font-family: 'Source Serif Pro', serif;
    text-align: center;
    margin-top: 10px;
    line-height: 1em;
    transition: 0.5s;
    transition-delay: 0.6s;
    opacity: 0;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    font-weight: 600;
}

#main .container_proj .card:hover .content .contentBx h3 {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    font-weight: bolder;
}

#main .container_proj .card .content .contentBx h3 span {
    font-size: 17px;
    letter-spacing: 0.05rem;
    font-weight: 500;
    text-transform: none;
    text-transform: initial;
}

#main .container_proj .card .content .sci {
    position: relative;
    bottom: 5px;
    display: -webkit-flex;
    display: flex;
    margin-left: -30px;
}

#main .container_proj .card .content .sci li {
    list-style: none;
    margin: 0 10px;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
    transition-delay: 0.2s;
}

#main .container_proj .card .content .sci .web{
    transition-delay: 0.4s;
}

#main .container_proj .card:hover .content .sci li {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
}

#main .container_proj .card .content .sci li a {
    color: white;
    font-size: 24px;
}

.btn_center {
    padding-top: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 25px;
    gap: 25px;
    padding-bottom: 100px;
}

@media (max-width: 750px) {
    .projectfr {
        position: absolute;
        width: 80vw;
        height: 29px;
        font-size: 50px;
    }
    .projectbg {
        display: none;
    }
}
.contact_section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  font-family: "Libre Baskerville", serif;
}

.contact_description {
  padding-top: 10px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.contact_body {
  padding-top: 20px;
  font-family: "Libre Baskerville", serif;
  font-size: 1.5em;
  text-align: center;
  letter-spacing: 1px;
}

.contact_right {
  -webkit-align-items: left;
          align-items: left;
}

.formEmail{
  letter-spacing: 0.08rem;
}
.fromEmail__mail{
  color: #45c7e7;
}

.form_control_input {
  width: 100%;
  border-radius: 15px;
  max-height: 45px;
  min-height: 45px;
  font-size: 23px;
  font-family: "Libre Baskerville", serif;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-top: 5px;
}

textarea:focus {
  outline: none;
  border: 1px solid #2196f3;
}

.form_control_textarea {
  width: 100%;
  border-radius: 15px;
  font-size: 23px;
  margin-bottom: 15px;
  font-family: "Libre Baskerville", serif;
  min-height: 300px;
  padding-left: 5px;
  padding-top: 5px;
}

.hover {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 210px;
  height: 50px;
  background-color: #4db6ac;
  border-radius: 99px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  margin-top: 30px;
  letter-spacing: 1px;
}

.hover:before,
.hover:after {
  position: absolute;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  width: 50%;
  height: 100%;
  transition: 0.25s linear;
  z-index: 1;
}

.hover:before {
  content: "";
  left: 0;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  background-color: #4db6ac;
}

.hover:after {
  content: "";
  right: 0;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  background-color: #44a69c;
}

.hover:hover {
  background-color: #46627f;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.hover:hover span {
  opacity: 0;
  z-index: -3;
}

.hover:hover:before {
  opacity: 0.5;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.hover:hover:after {
  opacity: 0.5;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.hover span {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  color: whitesmoke;
  font-family: "Libre Baskerville", serif;
  font-size: 24px;
  font-weight: 700;
  opacity: 1;
  transition: opacity 0.25s;
  z-index: 2;
}

.hover .social-link {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 25%;
  height: 100%;
  color: whitesmoke;
  font-size: 24px;
  text-decoration: none;
  transition: 0.25s;
}

.hover .social-link i {
  text-shadow: 1px 1px rgba(70, 98, 127, 0.7);
  -webkit-transform: scale(1);
          transform: scale(1);
}

.hover .social-link:hover {
  background-color: rgba(245, 245, 245, 0.1);
}

.hover .social-link:hover i {
  -webkit-animation: bounce 0.4s linear;
          animation: bounce 0.4s linear;
}

@-webkit-keyframes bounce {
  40% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  60% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce {
  40% {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
  60% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
  }
  80% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.img-dev {
  max-width: 100%;
  height: auto;
  -webkit-filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}

@media (max-width: 750px) {
  .contact_description {
    margin-top: 30px;
  }
  .contact_left,
  .formLogo {
    font-size: 2rem;
  }
  .contact_body {
    font-size: 1.2rem;
  }
  .contact_section {
    margin-top: 80px;
  }
  textarea {
    width: 100%;
  }
}

.footer_text {
    text-align: center;
    font-family: 'Vollkorn', serif;
    font-size: 25px;
    letter-spacing: 1px;
    padding-bottom: 20px;
    padding-right: 5px;
    padding-left: 5px;
}

.footer_ash {
  text-align: center;
  font-family: 'Vollkorn', serif;
  font-size: 20px;
  letter-spacing: 1px;
  padding-bottom: 5px;
  padding-right: 5px;
  padding-left: 5px;
}


.footer_icon {
    padding-bottom: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  #React-logo-footer {
    margin-bottom: 5px;
    -webkit-animation: React-logo-spin infinite 10s linear;
            animation: React-logo-spin infinite 10s linear;
  }
}

