.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

input {
  -webkit-appearance: none;
  position: relative;
  width: 56px;
  height: 28px;
  background-image: url(https://i.postimg.cc/857jHw2q/Screenshot-2020-04-16-at-1-07-06-PM.png);
  background-size: cover;
  border-radius: 50px;
  outline: none;
  transition: background-image .90s;
  box-shadow: 0px 2px 5px 1px gray;
}

input:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background-color: navy;
  border-radius: 50px;
  transition: all .9s;
  background-color: #F7CA33;
}

input:checked {
  background-image: url(https://i.postimg.cc/Hn0nstVK/Screenshot-2020-04-16-at-1-07-19-PM.png);
  transition: background-image .90s;
}

input:checked:before {
  transform: translate(100%);
  transition: all .9s;
  background-color: #ECF0F3;
}