@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

.exp-Bg {
    position: absolute;
    width: 988.59px;
    height: 165.55px;
    left: 32.68px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 100px;
    line-height: 169px;
    opacity: 0.2;
}

.exp-Fr {
    position: absolute;
    width: 496.2px;
    height: 54.86px;
    left: 53px;
    padding-top: 55px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 45px;
    line-height: 56px;
}

.work-exp {
    padding-top: 100px;
}

.work-exp-list {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin: 4rem 6vw;
    padding: 0;
    list-style: none;
    border-radius: 50px;
    justify-content: space-around;
}

.exp {
    margin: 30px auto;
    width: 300px;
    height: 420px;
    border-radius: 40px;
    box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.25), -5px -5px 30px 7px rgba(0, 0, 0, 0.22);
    cursor: pointer;
    transition: 0.4s;
    position: relative;
    display: block;
    overflow: hidden;
    text-decoration: none;
}

.exp__image {
    background-color: #fff;
    width: 100%;
    height: auto;
    border-radius: 40px;
    object-fit: cover;
}

.exp__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: calc(var(40) * 1px);
    background-color: rgb(221, 211, 211);
    transform: translateY(100%);
    transition: 0.2s ease-in-out;
}

.exp:hover .exp__overlay {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.exp__header {
    position: relative;
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: calc(var(40) * 1px) 0 0 0;
    background-color: rgb(221, 211, 211);
    padding: 2em;
    transform: translateY(-100%);
    transition: 0.2s ease-in-out;
}

.exp__arc {
    width: 5em;
    height: 5em;
    position: absolute;
    bottom: 100%;
    right: 0;
    z-index: 1;
}

.exp__arc path {
    fill: rgb(221, 211, 211);
    d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.exp:hover .exp__header {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

.exp__thumb {
    flex-shrink: 0;
    width: 3em;
    height: 3em;
}

.exp__title {
    font-size: 1.4em;
    margin: 0 0 0.3em;
    color: #312a2e;
    font-family: 'Source Serif Pro', serif;
    font-weight: bold;
}

.exp__status {
    font-size: 1em;
    color: #1a1517;
    font-family: 'Source Serif Pro', serif;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.exp__description {
    padding: 0 2em 2em;
    margin: 0;
    color: #130c10;
    -webkit-box-orient: vertical;
    text-align: justify;
    overflow: hidden;
    font-family: 'Source Serif Pro', serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.05rem;
}

@media (max-width: 750px) {
    .exp-Fr {
        position: absolute;
        width: 80vw;
        font-size: 30px;
    }
    .exp-Bg {
        display: none;
    }
    .work-exp-list {
        margin: 4rem 2vw;
    }
}